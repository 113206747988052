











































import Vue, { PropType } from 'vue'
import arrowDownIcon from '@/assets/images/blockChainBrowser/arrow-down-dark.png'

export default Vue.extend({
  props: {
    optionList: Array as PropType<
      {
        name: string
        value: string | number
      }[]
    >,
    selectedValue: [String, Number],
  },
  data() {
    return {
      arrowDownIcon,
      open: false,
    }
  },
  mounted() {
    window.addEventListener('click', this.clickGrobal)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickGrobal)
  },
  methods: {
    clickGrobal(e: MouseEvent) {
      const selectEl = this.$refs.select as HTMLElement
      const clickoutside = !selectEl.contains(e.target as HTMLElement)
      if (clickoutside) {
        this.open = false
      } else {
        this.open = !this.open
      }
    },
  },
  computed: {
    selectedName(): string {
      return (
        this.optionList.find((i) => i.value === this.selectedValue)?.name || ''
      )
    },
  },
})
