












































































import VueTypedMixins from 'vue-typed-mixins'
import CopyBtn from '../../CopyBtn.vue'
import { Popover } from 'element-ui'
import AddressOverview from '@/mixin/componentsMixin/AddressOverview'
import chartNoDataImg from '@/assets/images/blockChainBrowser/address/chart-no-data.png'
import Balance from './Balance.vue'
import CardShowTradeInTotal from './CardShowTradeInTotal.vue'
import ChartsInAddr from '@/components/chartsInAddr.vue'
export default VueTypedMixins(AddressOverview).extend({
  components: { CopyBtn, Popover, Balance, CardShowTradeInTotal, ChartsInAddr },
  props: {
    balance: [Number, Object],
    selectedValue: String,
    frozen: [Number, Object],
    optionsList: Array,
    handleSelectChange: Function,
  },
  data() {
    return {
      chartNoDataImg,
      scaleList: ['W1', 'M1', 'M6', 'Y1'],
      selectedScale: 'W1',
    }
  },
})
