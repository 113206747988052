































import Vue from 'vue'
import failIcon from '@/assets/images/blockChainBrowser/tradeDetail/fail.png'
import successIcon from '@/assets/images/blockChainBrowser/tradeDetail/success.png'
export default Vue.extend({
  props: {
    status: Boolean,
    size: {
      type: String,
      default: 'big',
    },
  },
  data() {
    return {
      failIcon,
      successIcon,
    }
  },
  computed: {
    statusStyle(): any {
      return {
        fontSize: this.size === 'big' ? '14px' : '12px',
      }
    },
  },
})
