import {
    getAddressTxCountOrigin,
    getAddrTxList_another,
    getVoterAddr,
    getVoterAddrCount,
    yccApi,
} from "@/service/api";
import { iExecAccount, iPack, iVote } from "@/types/address";
import { icustomized2Tx } from "@/types/trade";
import Rpc from "@/utils/Rpc";
import Vue from "vue";
import { getTxByHashes } from "ycc-api/dist/cmjs/service/blockDetail";
import { getConsensusList } from "@/utils/consensus";
import { checkIsEthAdr } from "@/utils/common";

export default Vue.extend({
    data() {
        return {
            value: "",
            focusedTab: "txRecord",
            total1: 0,
            pages: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            pages3: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            pages2: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            selectedOption: "1",
            loadingTxRecordTable: false,
            txRecordList: [] as icustomized2Tx[],
            voteList: [] as iVote[],
            selectedExecer: "",
            execAccount: [] as iExecAccount[],
            packList: [] as iPack[],
            loadingVote: false,
            loadingPack: false,
            selectedTxRecordFilterValue: "all",
        };
    },
    watch: {
        "$route.query.address"() {
            this.getAllExecBalance();
            this.getTxList();
            this.getVoter();
        },
        selectedTxRecordFilterValue(newValue) {
            this.pages.currentPage = 1;
            this.getTxList();
        },
    },
    mounted() {
        this.getAllExecBalance();
        this.getTxList();
        this.getVoter();
    },
    computed: {
        address() {
            return checkIsEthAdr(this.$route.query.address as string);
        },
        txRecordFilterOptionList() {
            return [
                {
                    name: this.$t("lang.trade.title"),
                    value: "all",
                },
                {
                    name: this.$t("lang.trade.filterSucceedTx"),
                    value: "filterSucceedTx",
                },
                {
                    name: this.$t("lang.trade.filterFailedTx"),
                    value: "filterFailedTx",
                },
                {
                    name: this.$t("lang.trade.filterSentTx"),
                    value: "filterSentTx",
                },
                {
                    name: this.$t("lang.trade.filterReceivedTx"),
                    value: "filterReceivedTx",
                },
            ];
        },
        tabList() {
            return [
                {
                    name: this.$t("lang.trade.txRecord"),
                    value: "txRecord",
                },
                {
                    name: this.$t("lang.trade.consensusRecord"),
                    value: "consensus",
                },
            ];
        },
        optionList() {
            return [
                { name: this.$t("lang.trade.txRecord"), value: "1" },
                { name: this.$t("lang.trade.consensusNode"), value: "2" },
            ];
        },
        execerOptionsList(): { name: string; value: string }[] {
            return this.execAccount.map((i) => ({
                name: i.execer,
                value: i.execer,
            }));
        },
        balance(): number {
            return (
                this.execAccount.find(
                    (i) => i.execer === this.selectedExecer
                ) || {
                    account: {
                        balance: 0,
                    },
                }
            )?.account?.balance;
        },
        frozen(): number {
            return (
                this.execAccount.find(
                    (i) => i.execer === this.selectedExecer
                ) || {
                    account: {
                        frozen: 0,
                    },
                }
            )?.account?.frozen;
        },
        txRecordReqParams(): any {
            switch (this.selectedTxRecordFilterValue) {
                case "all":
                    return [
                        {
                            match_one: [
                                {
                                    key: "from",
                                    value: this.address as string,
                                },
                                {
                                    key: "to",
                                    value: this.address as string,
                                },
                            ],
                            match: [],
                            not: [
                                {
                                    key: "action_name",
                                    value: "miner",
                                },
                            ],
                        },
                    ];
                case "filterSucceedTx":
                    return [
                        {
                            match_one: [
                                {
                                    key: "from",
                                    value: this.address as string,
                                },
                                {
                                    key: "to",
                                    value: this.address as string,
                                },
                            ],
                            match: [
                                {
                                    query: {
                                        match_one: [
                                            {
                                                key: "is_para",
                                                value: true,
                                            },
                                            {
                                                key: "success",
                                                value: true,
                                            },
                                        ],
                                    },
                                },
                            ],
                            not: [
                                {
                                    key: "action_name",
                                    value: "miner",
                                },
                            ],
                        },
                    ];
                case "filterFailedTx":
                    return [
                        {
                            match_one: [
                                {
                                    key: "from",
                                    value: this.address as string,
                                },
                                {
                                    key: "to",
                                    value: this.address as string,
                                },
                            ],
                            match: [
                                {
                                    key: "success",
                                    value: false,
                                },
                                {
                                    key: "is_para",
                                    value: false,
                                },
                            ],
                            not: [
                                {
                                    key: "action_name",
                                    value: "miner",
                                },
                            ],
                        },
                    ];
                case "filterSentTx":
                    return [
                        {
                            match_one: [
                                {
                                    key: "from",
                                    value: this.address as string,
                                },
                            ],
                            match: [],
                            not: [
                                {
                                    key: "action_name",
                                    value: "miner",
                                },
                            ],
                        },
                    ];
                case "filterReceivedTx":
                    return [
                        {
                            match_one: [
                                {
                                    key: "to",
                                    value: this.address as string,
                                },
                            ],
                            match: [],
                            not: [
                                {
                                    key: "action_name",
                                    value: "miner",
                                },
                            ],
                        },
                    ];
                default:
                    return [];
            }
        },
    },
    methods: {
        getAllExecBalance() {
            Rpc.getAllExecBalance(this.address as string).then((res) => {
                if (res.error === null) {
                    this.execAccount = res.result.execAccount;
                    this.selectedExecer = res.result.execAccount[0].execer;
                }
            });
        },
        checkGroup(arr: icustomized2Tx[]) {
            arr.map((current, index) => {
                if (current.group_count > 1) {
                    current.tradeG = 1; //0-非交易组，1-交易组，3-交易组末条, 2-交易组首条
                    // current.tradeG = (current.tx.fee === 0 && arr[index-1].tx.fee!==0)? 2 : 1
                    if (current.fee !== 0) {
                        current.tradeG = 2;
                    } else {
                        if (index < arr.length) {
                            console.log(index);
                            current.tradeG = arr[index].next === "" ? 3 : 1;
                        } else {
                            current.tradeG = 3;
                        }
                    }
                } else {
                    current.tradeG = 0;
                }
            });
            return arr;
        },
        getTxList() {
            this.loadingTxRecordTable = true;
            getAddressTxCountOrigin(this.txRecordReqParams).then((res) => {
                if (res.error === null) {
                    this.total1 = res.result;
                    this.pages.total = res.result;
                    // this.pages.total = res.result > 10000 ? 10000 : res.result;
                    getAddrTxList_another(
                        this.pages.currentPage,
                        this.pages.pageSize,
                        this.txRecordReqParams[0]
                    ).then((res) => {
                        if (res.error === null) {
                            this.txRecordList = this.checkGroup(res.result);
                            this.loadingTxRecordTable = false;
                        }
                    });
                }
            });
        },
        setValue(v: string) {
            this.value = v;
        },
        sizeChange2(size: number) {
            this.pages2.pageSize = size;
            this.pageChange2(1);
        },
        pageChange2(page: number) {
            this.pages2.currentPage = page;
            this.loadingVote = true;

            getVoterAddr(
                this.address as string,
                this.pages2.currentPage,
                this.pages2.pageSize
            ).then((res) => {
                if (res.error === null) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let obj = {} as any;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    res.result.forEach((i: any) => {
                        i.voter_addr.forEach((item: string) => {
                            // i.voter_detail[item]++
                            if (!obj[item]) obj[item] = 1;
                            else obj[item]++;
                        });
                        i.voter_detail = obj;
                        obj = {};
                    });
                    this.voteList = res.result;
                    this.loadingVote = false;
                    // const txHashes = res.result.map((i: any) => i.hash);
                    // this.setVoteReward(txHashes);
                }
            });
        },
        sizeChange(size: number) {
            this.pages.pageSize = size;
            this.pageChange(1);
        },
        pageChange(page: number) {
            this.pages.currentPage = page;
            this.loadingTxRecordTable = true;
            getAddrTxList_another(
                this.pages.currentPage,
                this.pages.pageSize,
                this.txRecordReqParams[0]
            ).then((res) => {
                if (res.error === null) {
                    this.txRecordList = this.checkGroup(res.result);
                    this.loadingTxRecordTable = false;
                }
            });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        groupCellName(val: any) {
            switch (val.row.tradeG) {
                case 0:
                    return "";
                case 1:
                    return "tx-middle";
                case 2:
                    return "tx-start";
                case 3:
                    return "tx-end";
                default:
                    return "";
            }
        },
        getVoter() {
            getVoterAddrCount(this.address as string).then((res) => {
                if (res.error === null) {
                    this.pages2.total = res.result;
                    getVoterAddr(
                        this.address as string,
                        this.pages2.currentPage,
                        this.pages2.pageSize
                    ).then((res) => {
                        if (res.error === null) {
                            // console.log(res, 'vote');
                            let obj = {} as any;
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            res.result.forEach((i: any) => {
                                i.voter_addr.forEach((item: string) => {
                                    // i.voter_detail[item]++
                                    if (!obj[item]) obj[item] = 1;
                                    else obj[item]++;
                                });
                                i.voter_detail = obj;
                                obj = {};
                            });
                            this.voteList = res.result;
                        }
                    });
                }
            });
        },
    },
});
