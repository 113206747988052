







































































import ChainSearch from "@/components/pc/BlockChainBrowser/ChainSearch.vue";
import AddressOverview from "@/components/pc/BlockChainBrowser/AddressOverview/index.vue";
import VueTypedMixins from "vue-typed-mixins";
import address from "@/mixin/address";
import PageContainer from "@/components/pc/BlockChainBrowser/PageContainer.vue";
import TabList from "@/components/pc/BlockChainBrowser/TabList/index.vue";
import TradeTable2 from "@/components/pc/BlockChainBrowser/TradeTable2.vue";
import DataFilter from "@/components/pc/BlockChainBrowser/DataFilter/index.vue";
import VotePackTable from "./components/votePackTable.vue";
export default VueTypedMixins(address).extend({
  components: {
    ChainSearch,
    AddressOverview,
    TabList,
    DataFilter,
    TradeTable2,
    VotePackTable,
    PageContainer,
  },
  computed: {},
});
