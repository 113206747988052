import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  methods: {
    generateD1() {
      const list = [];
      for (let i = 0; i < 7; ++i) {
        const m = moment()
          .hour(0)
          .minute(0)
          .second(0)
          .add(-i, "days");
        list.push(m);
      }
      return list.map((i) => i.unix());
    },
    generateW1() {
      const list = [];
      for (let i = 0; i < 7; ++i) {
        const m = moment()
          .hour(0)
          .minute(0)
          .second(0)
          .add(-i * 7, "days");
        list.push(m);
      }
      return list.map((i) => i.unix());
    },
    generateM1() {
      const list = [];
      for (let i = 0; i < 7; ++i) {
        const m = moment()
          .hour(0)
          .minute(0)
          .second(0)
          .add(-i, "month");
        list.push(m);
      }
      return list.map((i) => i.unix());
    },
    generateM6() {
      const list = [];
      for (let i = 0; i < 7; ++i) {
        const m = moment()
          .hour(0)
          .minute(0)
          .second(0)
          .add(-i * 6, "month");
        list.push(m);
      }
      return list.map((i) => i.unix());
    },
    generateY1() {
      const list = [];
      for (let i = 0; i < 7; ++i) {
        const m = moment()
          .hour(0)
          .minute(0)
          .second(0)
          .add(-i, "year");
        list.push(m);
      }
      return list.map((i) => i.unix());
    },
  },
});
