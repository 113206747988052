var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-votePackTable"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"vote-table",attrs:{"data":_vm.list,"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","default-expand-all":""}},[_c('TableColumn',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._l((Object.keys(props.row.voter_detail)),function(item,index){return _c('div',{key:index,staticClass:"line"},[_c('router-link',{staticClass:"adr",attrs:{"to":{
              path: '/address',
              query: {
                address: item,
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item,10))+" ")]),_c('span',{staticClass:"vote-node"},[_vm._v(_vm._s(_vm.$t("lang.block.votingNode")))]),_c('span',{staticClass:"reward"},[_vm._v(_vm._s(Number(props.row.voter_detail[item]) * 0.25)+" YCC")])],1)}),_c('div',{staticClass:"line"},[_c('router-link',{staticClass:"adr",attrs:{"to":{
              path: '/address',
              query: {
                address: _vm.foundation,
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(_vm.foundation,10))+" ")]),_c('span',{staticClass:"vote-node"},[_vm._v(_vm._s(_vm.$t("lang.block.foundationNode")))]),_c('span',{staticClass:"reward"},[_vm._v("6 YCC")])],1)]}}])}),_c('TableColumn',{attrs:{"label":_vm.$t('lang.trade.hash')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-footer-color"},[_c('router-link',{attrs:{"to":{
              path: '/tradeHash',
              query: {
                hash: row.hash,
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(row.hash,10))+" ")])],1)]}}])}),_c('TableColumn',{attrs:{"label":_vm.$t('lang.address.nodeAdr')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-footer-color"},[_c('router-link',{attrs:{"to":{
              path: '/address',
              query: {
                address: row.maker_addr[0],
              },
            }}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(row.maker_addr[0],10))+" ")])],1)]}}])}),_c('TableColumn',{attrs:{"label":_vm.$t('lang.trade.type'),"width":"150","align":"center"}},[[_c('div',{staticClass:"text-text-color"},[_vm._v(_vm._s(_vm.$t("lang.block.packingNode")))])]],2),_c('TableColumn',{attrs:{"label":_vm.$t('lang.block.blockReward'),"width":"150","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-text-color"},[_vm._v(_vm._s(row.amount / 1e8)+" YCC")])]}}])}),_c('TableColumn',{attrs:{"label":_vm.$t('lang.trade.time'),"align":"right","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"text-text-color"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(row.block_time))+" ")])]}}])}),_c('TableColumn',{attrs:{"label":"","width":"40"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }