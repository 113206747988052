












import Clipboard from 'clipboard'
import { message } from 'ant-design-vue'
import Vue from 'vue'

export default Vue.extend({
  props: {
    copyTxt: [String, Number],
  },
  mounted() {
    this.init()
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    init() {
      const clipboard = new Clipboard(this.$refs.btn as Element)
      clipboard.on('success', (e) => {
        message.success(this.$t('lang.components.copySuc') as string)
        e.clearSelection()
      })

      clipboard.on('error', () => {
        message.error(this.$t('lang.components.copyErr') as string)
      })
    },
  },
})
