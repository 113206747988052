












































































































import { Table, TableColumn } from "element-ui";
import Vue from "vue";
export default Vue.extend({
  components: {
    "el-table": Table,
    TableColumn,
  },
  props: {
    list: Array,
    loading: Boolean,
  },
  computed: {
    foundation() {
      return process.env.VUE_APP_FOUNDATION;
    },
  },
});
