





























import { filterNum } from "@/utils/utils";
import VueTypedMixins from "vue-typed-mixins";
import Select from "../../CurrencySelect.vue";
export default VueTypedMixins().extend({
  components: { Select },
  props: {
    balance: [Number, Object],
    selectedValue: String,
    frozen: [Number, Object],
    optionsList: Array,
    handleSelectChange: Function,
  },
  methods: {
    filterNum,
  },
});
