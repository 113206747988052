















































































































































import Vue, { PropType } from 'vue'
import { tradeAccuracy } from '@/utils/common'
import { Table, TableColumn } from 'element-ui'
import PageContainer from '@/components/pc/BlockChainBrowser/PageContainer.vue'
import TxStatus from '../txStatus.vue'
import arrowRightBlue from '@/assets/images/blockChainBrowser/blockDetail/arrow-right-blue.png'
import arrowRightDark from '@/assets/images/blockChainBrowser/blockDetail/arrow-right-dark.png'
import { icustomized2Tx } from '@/types/trade'

/**
 * TradeTable2.vue 组件 和TradeTable/index.vue 组件的区别在于props传参中的Trades的类型。
 */

export default Vue.extend({
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    PageContainer,
    TxStatus,
  },
  props: {
    hidePageContainer: {
      type: Boolean,
      default: false,
    },
    Trades: Array as PropType<icustomized2Tx[]>,
    Loading: Boolean,
    groupCellName: Function,
    pageChange: Function,
    sizeChange: Function,
    pages: Object,
  },
  data() {
    return {
      arrowRightBlue,
      arrowRightDark,
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isSuccess(execer: any, ty: any) {
      return !tradeAccuracy(execer, ty)
    },
  },
})
