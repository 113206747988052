




import * as echarts from "echarts/core";
import { LineSeriesOption, LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { getAccountRecords } from "@/service/api";
import moment from "moment";
import { checkIsEthAdr } from "../utils/common";
type ECOption = echarts.ComposeOption<LineSeriesOption>;

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer]);

import chartsMixin from "@/mixin/componentsMixin/charts";
import VueTypedMixins from "vue-typed-mixins";
import { nFormatter } from "@/utils/common";

export default VueTypedMixins(chartsMixin).extend({
    props: {
        scale: String,
        grid: Object,
    },
    data() {
        return {
            xData: [],
            seriesData: [],
        };
    },
    methods: {
        generate() {
            switch (this.scale) {
                case "D1":
                    return this.generateD1();
                case "W1":
                    return this.generateW1();
                case "M1":
                    return this.generateM1();
                case "M6":
                    return this.generateM6();
                case "Y1":
                    return this.generateY1();
            }
        },
        draw() {
            const times = this.generate();
            times &&
                getAccountRecords(
                    checkIsEthAdr(this.$route.query.address as string),
                    times,
                    "ycc"
                ).then((ret) => {
                    if (ret.error == null) {
                        this.xData = ret.result;
                        const myChart = echarts.init(
                            this.$refs.charts as HTMLElement
                        );
                        myChart.setOption({
                            tooltip: {
                                trigger: "axis",
                            },
                            xAxis: {
                                type: "category",
                                axisLabel: {
                                    textStyle: {
                                        color: "#7c88ad",
                                    },
                                },
                                axisTick: {
                                    show: false,
                                },
                                data: times
                                    ?.map((i) => {
                                        return moment
                                            .unix(i)
                                            .format("YYYY.MM.DD");
                                    })
                                    .reverse(),
                            },
                            yAxis: {
                                type: "value",
                                axisLabel: {
                                    formatter: (item: number) => {
                                        return nFormatter(item);
                                    },
                                    textStyle: {
                                        color: "#7c88ad",
                                    },
                                },
                            },
                            series: [
                                {
                                    data: this.xData
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        .map(
                                            (i: any) =>
                                                (i && i[0] && i[0].balance) || 0
                                        )
                                        .map((i) => i / 1e8)
                                        .reverse(),
                                    type: "line",
                                    areaStyle: {
                                        normal: {
                                            // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                                            color: new echarts.graphic.LinearGradient(
                                                0,
                                                0,
                                                0,
                                                1,
                                                [
                                                    {
                                                        offset: 0,
                                                        color: "#2545CB", // 0% 处的颜色
                                                    },
                                                    {
                                                        offset: 0.6,
                                                        color: "#9FAEE9", // 40% 处的颜色
                                                    },
                                                    {
                                                        offset: 1,
                                                        color: "white", // 100% 处的颜色
                                                    },
                                                ]
                                            ), //背景渐变色
                                            lineStyle: {
                                                // 系列级个性化折线样式
                                                type: "solid",
                                                color: "#2545CB", //折线的颜色
                                            },
                                        },
                                    },
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle: {},
                                },
                            ],
                            grid: this.grid,
                        } as ECOption);
                    }
                });
        },
    },
    mounted() {
        this.draw();
    },
    watch: {
        "$route.query.address"() {
            this.draw();
        },
        scale() {
            this.draw();
        },
    },
});
