






















import Vue, { PropType } from 'vue'
import { iTab } from './types'
export default Vue.extend({
  props: {
    tabList: Array as PropType<iTab[]>,
    focusedTab: String,
    setFocusedTab: Function,
  },
})
