var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_container"},[_c('div',{staticClass:"top flex items-center"},[_c('div',{staticClass:"address_label mr-2.5 my-title"},[_vm._v(" "+_vm._s(_vm.$t('lang.address.address'))+" ")]),_c('div',{staticClass:"address_value mr-2.5"},[_vm._v(" "+_vm._s(_vm.$route.query.address)+" ")]),_c('copy-btn',{staticClass:"mr-2.5",attrs:{"copyTxt":_vm.$route.query.address}},[_vm._v(" "+_vm._s(_vm.$t('lang.components.copy'))+" ")]),_c('div',{staticClass:"little-qrcode flex items-center justify-center"},[_c('Popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('canvas',{staticStyle:{"margin":"13px"},attrs:{"id":"qrcode"}}),_c('img',{staticClass:"w-5 h-5 cursor-pointer",attrs:{"slot":"reference","src":require("@/assets/images/blockChainBrowser/address/qrcodeIcon.png"),"alt":""},slot:"reference"})])],1)],1),_c('div',{staticClass:"flex"},[_c('Balance',{staticClass:"flex-shrink-0",attrs:{"balance":_vm.balance,"frozen":_vm.frozen,"selectedValue":_vm.selectedValue,"optionsList":_vm.optionsList,"handleSelectChange":_vm.handleSelectChange}}),_c('div',{staticClass:"w-full flex flex-col overflow-hidden"},[_c('div',{staticClass:"flex-grow relative"},[_c('div',{staticClass:"text-right",staticStyle:{"padding-right":"30px"}},_vm._l((_vm.scaleList),function(s,i){return _c('span',{key:i,staticClass:"text-text-color ml-7 cursor-pointer",class:{
              ' text-footer-color': s === _vm.selectedScale,
            },on:{"click":function($event){_vm.selectedScale = s}}},[_vm._v(" "+_vm._s(s)+" ")])}),0),_c('div',{staticStyle:{"padding-left":"30px","padding-right":"30px","margin-top":"10px"}},[_c('charts-in-addr',{staticStyle:{"height":"190px"},attrs:{"scale":_vm.selectedScale,"grid":{
              top: '10px',
              bottom: '25px',
              left: '50px',
              right: '20px',
            }}})],1)]),_c('card-show-trade-in-total',{staticClass:"flex-shrink-0",attrs:{"list":_vm.tradeInTotalList}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }