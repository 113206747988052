var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trade-table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.Loading),expression:"Loading"}],staticClass:"data-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.Trades,"stripe":"","cell-class-name":_vm.groupCellName,"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading"}},[_c('template',{slot:"empty"},[_c('div',[(!_vm.Loading && _vm.Trades.length === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('lang.page.nodata'))+" ")]):_vm._e()])]),_c('el-table-column',{attrs:{"label":"","width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',[_c('div',{class:{
              'start-line': item.row.tradeG === 2,
              'middle-line': item.row.tradeG === 1,
              'end-line': item.row.tradeG === 3,
            }}),(
              item.row.tradeG === 1 ||
              item.row.tradeG === 2 ||
              item.row.tradeG === 3
            )?_c('img',{staticClass:"w-5 h-5 max-w-none z-20 relative",attrs:{"src":require("../../../assets/images/blockChainBrowser/tradeDetail/txGroupIcon.png")}}):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('lang.trade.hash'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',[_c('router-link',{staticClass:"light flex-left text-footer-color",attrs:{"to":("/tradeHash?hash=" + (item.row.hash))}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.row.hash,8))+" ")])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"hash","label":_vm.$t('lang.trade.sender'),"width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('router-link',{staticClass:"text-footer-color",attrs:{"to":("/address?address=" + (item.row.from))}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.row.from,8))+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":item.row.tradeG === 1 ||
            item.row.tradeG === 2 ||
            item.row.tradeG === 3
              ? _vm.arrowRightBlue
              : _vm.arrowRightDark,"alt":""}})]}}])}),_c('el-table-column',{attrs:{"prop":"hash","label":_vm.$t('lang.trade.receiver'),"width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('router-link',{staticClass:"text-footer-color",attrs:{"to":("/address?address=" + (item.row.to))}},[_vm._v(" "+_vm._s(_vm._f("filterHash")(item.row.to,8))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('lang.block.txCount'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',{staticClass:"text-darkBlue"},[_vm._v(" "+_vm._s(_vm._f("TradeValue")(item.row))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('lang.trade.fee'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',{staticClass:"text-darkBlue"},[_vm._v(" "+_vm._s(_vm._f("filterFee")(item.row.fee))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('lang.trade.receiveStatus'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',[_c('tx-status',{attrs:{"status":item.row.success}})],1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('lang.trade.time'),"width":"170"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',{staticClass:"text-darkBlue"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(item.row.block_time))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('lang.trade.type'),"width":"90","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('div',{staticClass:"text-darkBlue"},[_vm._v(" "+_vm._s(item.row.action_name === 'unknown' ? 'none' : item.row.action_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"width":"40"}})],2),(!_vm.hidePageContainer)?_c('div',{staticClass:"flex justify-end pt-7"},[_c('page-container',{attrs:{"currentPage":_vm.pages.currentPage,"pageSize":_vm.pages.pageSize,"total":_vm.pages.total},on:{"pageChange":_vm.pageChange,"sizeChange":_vm.sizeChange}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }