<template>
  <div class="page-container">
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="currentPageNum"
      :page-size="pageSize"
      layout="slot,prev"
      :total="total"
      style="padding-right: 0;"
    >
      <el-button
        :disabled="firstPageBtnDisabled"
        class="first-pager"
        @click="toFirstPage"
      >
        {{ $t('lang.page.first') }}
      </el-button>
    </el-pagination>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="currentPageNum"
      :page-size="pageSize"
      :page-sizes="[10, 20, 30]"
      layout="pager,next,slot,total,sizes,jumper"
      :total="total"
      :pager-count="5"
      style="padding-left: 0;"
    >
      <el-button
        :disabled="lastPageBtnDisabled"
        class="last-pager"
        @click="toLastPage"
      >
        {{ $t('lang.page.last') }}
      </el-button>
    </el-pagination>
    <div class="confirm flex items-center justify-center">
      {{ $t('lang.page.confirm') }}
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'pages',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPageNum: this.currentPage,
      firstPageBtnDisabled: true,
      lastPageBtnDisabled: false,
    }
  },
  watch: {
    currentPage(newVal) {
      this.currentPageNum = newVal
      this.firstPageBtnDisabled = this.currentPageNum === 1 ? true : false
      this.lastPageBtnDisabled =
        this.currentPageNum === this.lastPageNum ? true : false
    },
  },
  computed: {
    lastPageNum() {
      return Math.ceil(this.total / this.pageSize)
    },
  },
  methods: {
    handleCurrentChange(page) {
      this.firstPageBtnDisabled = page === 1 ? true : false
      this.lastPageBtnDisabled = page === this.lastPageNum ? true : false
      this.currentPageNum = page
      this.$emit('pageChange', page)
    },
    handleSizeChange(size) {
      this.$emit('sizeChange', size)
    },
    toFirstPage() {
      this.handleCurrentChange(1)
    },
    toLastPage() {
      this.handleCurrentChange(this.lastPageNum)
    },
  },
})
</script>
<style lang="scss">
.page-container {
  .el-pagination__jump .el-pagination__total {
    color: #8093a7;
  }
  .el-pagination {
    float: left;
    .el-pager li {
      background-color: #ffffff;
      color: #8093a7;
      font-weight: 400;
    }
    .el-pager li:not(.disabled).active {
      background-color: #f6f6f6;
      color: #6368de;
    }
    .el-pager li {
      font-weight: 500;
      font-size: 12px;
    }
    .btn-next,
    .btn-prev {
      background: white;
    }
  }
  .confirm {
    width: 58px;
    height: 30px;
    background: #2545cb;
    border-radius: 15px;
    margin-left: 13px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: PingFangSC-Regular;
    cursor: pointer;
  }
  .el-pagination__jump {
    margin-left: 5px;
  }
  .first-pager,
  .last-pager {
    background: white;
    color: #8093a7;
  }
  .last-pager {
    margin-right: 10px;
  }
}
</style>
