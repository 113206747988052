

















import Vue, { PropType } from "vue";
import { iOptionItem } from "./types";
import MySelect from "@/components/pc/Select.vue";
export default Vue.extend({
  components: {
    MySelect,
  },
  props: {
    value: [String, Number],
    setValue: Function,
    optionList: Array as PropType<iOptionItem[]>,
    hideSelect: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
});
