






















































import Vue, { PropType } from 'vue'
export default Vue.extend({
  props: {
    size: {
      type: String as PropType<'big' | 'small'>,
      default: 'big',
    },
    optionPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'left',
    },
    searchPlaceholder: {
      type: String,
    },
    selectedValue: [String, Number],
    optionsList: {
      type: Array as PropType<{ name: string; value: any }[]>,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      open: false,
      filterValue: '',
    }
  },
  computed: {
    selectedOptionName() {
      return this.optionsList.find((i) => i.value === this.selectedValue)?.value
    },
    filteredOptionsList(): any[] {
      if (this.filterValue === '') {
        return this.optionsList
      } else {
        return this.optionsList.filter(
          (i) => i.name.indexOf(this.filterValue) !== -1,
        )
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.foo)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.foo)
  },
  methods: {
    foo(e: Event) {
      const clickOutSide = !(this.$refs.select as HTMLElement).contains(
        e.target as Node,
      )
      const clickSearchBox = (this.$refs.searchBox as HTMLElement).contains(
        e.target as Node,
      )
      if (clickSearchBox) {
        this.open = true
      } else {
        if (clickOutSide) {
          this.open = false
        } else {
          this.open = !this.open
        }
      }
    },
  },
})
