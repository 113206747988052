import Vue from "vue";
import QRCode from "qrcode";
import { getAddressTxCount } from "@/service/api";
import Rpc from "@/utils/Rpc";
import { checkIsEthAdr } from "@/utils/common";
export default Vue.extend({
    data() {
        return {
            txCount: 0,
            receive: 0,
            send: 0,
            value: 0,
        };
    },
    watch: {
        "$route.query.address"() {
            this.getAddrOverview();
            this.init();
            getAddressTxCount(
                checkIsEthAdr(this.$route.query.address as string)
            ).then((ret) => {
                if (ret.error === null) {
                    this.txCount = ret.result;
                }
            });
        },
    },
    mounted() {
        this.getAddrOverview();
        this.init();
        getAddressTxCount(
            checkIsEthAdr(this.$route.query.address as string)
        ).then((ret) => {
            if (ret.error === null) {
                this.txCount = ret.result;
            }
        });
    },
    computed: {
        tradeInTotalList(): { name: string; value: number }[] {
            return [
                // {
                //   name: this.$t("lang.address.totalReception") as string,
                //   value: this.receive,
                // },
                // {
                //   name: this.$t("lang.address.totalSent") as string,
                //   value: this.send,
                // },
                {
                    name: this.$t("lang.address.totalValue") as string,
                    value: this.value,
                },
                {
                    name: this.$t("lang.block.txCount") as string,
                    value: this.txCount,
                },
            ];
        },
    },
    methods: {
        getAddrOverview() {
            Rpc.getAddrOverview(
                checkIsEthAdr(this.$route.query.address as string)
            ).then((res) => {
                if (res.error === null) {
                    const balance = res.result.balance;
                    const receiver = res.result.reciver;
                    this.receive = receiver;
                    this.send = receiver - balance;

                    fetch("/market")
                        .then((ret) => ret.json())
                        .then((ret) => {
                            if (ret.msg === "succeed") {
                                const data = ret.data.data.USDT.YCC;
                                this.value = data.last * balance;
                            }
                        });
                }
            });
        },
        init() {
            QRCode.toCanvas(
                document.getElementById("qrcode"),
                checkIsEthAdr(this.$route.query.address as string),
                {
                    color: {
                        dark: "#1F3470",
                        light: "#0000",
                    },
                    width: 130,
                    margin: 0,
                },
                function(error) {
                    if (error) console.error(error);
                }
            );
        },
    },
});
