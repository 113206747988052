































import { filterNum } from "@/utils/utils";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    list: Array as PropType<{ name: string; value: string | number }[]>,
  },
  mounted() {
    this.setFontSize();
  },
  methods: {
    filterNum,
    setFontSize() {
      const father = this.$refs.father as HTMLElement[];
      const a = this.$refs.a as HTMLElement[];
      const b = this.$refs.b as HTMLElement[];
      const c = this.$refs.c as HTMLElement[];
      let beSmall = false;
      [...a, ...b, ...c].some((el: HTMLElement) => {
        if (el.offsetWidth >= father[0].offsetWidth) {
          beSmall = true;
        }
      });
      beSmall &&
        [...a, ...b, ...c].forEach((el: HTMLElement) => {
          el.style.fontSize = "14px";
        });
    },
  },
  watch: {
    list(newV) {
      this.setFontSize();
    },
  },
});
